import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { Company, CompanyKey } from 'company-finder-common';
import { isEmptyInputValue } from '../../../../_common/utilities/forms/form-util';

@Component({
  selector: 'blue-knight-information',
  templateUrl: './blue-knight-information.component.html',
  styleUrls: ['./blue-knight-information.component.scss'],
})
export class BlueKnightInformationComponent extends ComponentBase {
  @Input()
  public company: Company;

  public properties: CompanyKey[] = [
    'companyObjective',
    'alignedGoal',
    'approachUsecase',
    'mentorship',
    'currentTeamSizeRange',
    'compositionAndGrowth',
    'conferencesAndEvents',
    'overallTRL',
    'alignedTRL',
  ];

  public get hasData(): boolean {
    return this.properties.some(
      (property) => !isEmptyInputValue(this.company[property])
    );
  }
}
